import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import "../../VideoStyle.css"

import HeaderBase, {
  LogoLink as LogoLinkBase,
  NavLinks,
  NavLink as NavLinkBase,
  PrimaryLink as PrimaryLinkBase
} from "../headers/light.js";
import { Container as ContainerBase, ContentWithVerticalPadding, Content2Xl } from "components/misc/Layouts.js";
import { SectionHeading } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import logoImageSrc from "images/code_pirates_logo_white_bg.png";
import mondayLogoSrc from "images/monday logo_white.png";
import videoSrc from "images/demo_video.mp4";
import posterSrc from "images/demo_video_poster2.png";
import serverIllustrationImageSrc from "images/pirate.png";
import { Link as ScrollLink } from 'react-scroll';
import styled from 'styled-components';

const HeadingInline = styled.div`
  ${tw`flex items-center justify-center md:justify-start text-left`}
`;

const ResponsiveImage = styled.img`
  width: 7em; // Adjust width as necessary
  height: auto; // Keep height auto for aspect ratio
  margin-left: 10px; // Adds some space between the text and the image
  align-self: center; // Aligns image with the center of the line for better visual alignment
  display: inline-block; // Ensures the image is in-line with text
  vertical-align: middle; // Vertically aligns the image with the middle of the text
`;

const ResponsiveImage2 = styled.img`
  width: 7em; // Adjust width as necessary
  height: auto; // Keep height auto for aspect ratio
  margin-left 0.2em;
  top: 2px;
  position: relative;
`;

const PrimaryBackgroundContainer = tw.div`-mx-8 px-8 bg-primary-900 text-gray-100`;
const Header = tw(HeaderBase)`max-w-none -mt-8 py-8 -mx-8 px-8`;
const NavLink = tw(NavLinkBase)`lg:text-gray-100 lg:hocus:text-gray-300 lg:hocus:border-gray-100`;
const LogoLink = tw(LogoLinkBase)`text-gray-100 hocus:text-gray-300`;
const PrimaryLink = tw(PrimaryLinkBase)`shadow-raised lg:bg-primary-400 lg:hocus:bg-primary-500`;

const Container = tw(ContainerBase)``;
const Row = tw.div`flex items-center flex-col lg:flex-row`;
const Column = tw.div`lg:w-1/2`;
const TextColumn = tw.div`text-center lg:text-left`;
const IllustrationColumn = tw(Column)`mt-16 lg:mt-0 lg:ml-16`;
const Heading = tw(SectionHeading)`max-w-3xl lg:max-w-4xl lg:text-left leading-tight`;
const Heading2 = tw(Heading)`max-w-3xl lg:max-w-4xl lg:text-left leading-tight`;
const Description = tw(SectionDescription)`mt-4 max-w-2xl text-gray-100 lg:text-base mx-auto lg:mx-0 flex items-center justify-center md:justify-start text-left`;
const PrimaryButton = tw(PrimaryButtonBase)`mt-8 text-sm sm:text-base px-6 py-5 sm:px-10 sm:py-5 bg-primary-400 inline-block hocus:bg-primary-500`;
const Image = tw.img`w-144 ml-auto`

//const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;


// Define a styled ScrollLink using the styles from NavLink
const StyledScrollLink = styled(ScrollLink)`
  ${tw`lg:text-gray-100 lg:hocus:text-gray-300 lg:hocus:border-gray-100 cursor-pointer`}
`;

export default ({
  loomUrl = "https://www.loom.com/share/163cdd182c3e4f899df24df2d28a0f38?sid=959be43b-d960-43a6-9ebc-fdc5b9ca1435",
  heading = "Track your items with QR codes on",
  description = "Click the link below to install Q-ARGH in monday.com",
  primaryButtonText = "Start Your Free Trial",
  primaryButtonUrl = "https://auth.monday.com/oauth2/authorize?client_id=1ddf1af1629ada24c310b77963426bd5&response_type=install",
  imageSrc = serverIllustrationImageSrc,ƒ
}) => {
  const logoLink = (
    <LogoLink href="/">
      <img src={logoImageSrc} alt="Logo" />
      Code Pirates Syndicate
    </LogoLink>
  );
  const navLinks = [
    <NavLinks key="1">
      <NavLinkBase as="a" href="#features">Features</NavLinkBase>
      <NavLinkBase as="a" href="#pricing">Pricing</NavLinkBase>
      {/* <NavLinkBase as="a" href="#faqsSection">FAQs</NavLinkBase> */}
      <NavLinkBase as="a" href="#testimonials">Testimonials</NavLinkBase>
      <StyledScrollLink to="faqsSection" smooth={true} duration={500} offset={-50} spy={true}>
      </StyledScrollLink>
      <StyledScrollLink to="testimonials" smooth={true} duration={500} offset={-50} spy={true}>
      </StyledScrollLink>
    </NavLinks>
  ];
  return (
    <>
    <PrimaryBackgroundContainer>
      <Content2Xl>
        <Header logoLink={logoLink} links={navLinks} />
        <Container>
          <ContentWithVerticalPadding>
            <Row>
              <TextColumn>
                <HeadingInline>
                  <Heading>{heading}<ResponsiveImage src={mondayLogoSrc} alt="monday.com Logo" /></Heading>
                </HeadingInline>
                <Description>
                    Click the link below to install Q-ARGH
                  </Description>
                <PrimaryButton as="a" href={primaryButtonUrl}>{primaryButtonText}</PrimaryButton>
                <a href="https://auth.monday.com/oauth2/authorize?client_id=1ddf1af1629ada24c310b77963426bd5&response_type=install">
              </a>
              </TextColumn>
              <IllustrationColumn>
              <div className="video-container">
                <video height="100%" controls poster={posterSrc}>
                  <source src={videoSrc} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
              </IllustrationColumn>
            </Row>
          </ContentWithVerticalPadding>
        </Container>
      </Content2Xl>
    </PrimaryBackgroundContainer>
    </>
  );
};
