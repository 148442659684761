import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithPrimaryBackground.js";
import Features from "components/features/ThreeColWithSideImageWithPrimaryBackground.js";
import MainFeature from "components/features/TwoColWithButton.js";
import Pricing from "components/pricing/ThreePlansWithHalfPrimaryBackground.js";
import Testimonial from "components/testimonials/SimplePrimaryBackground.js";
import FAQ from "components/faqs/TwoColumnPrimaryBackground.js";
import Footer from "components/footers/FiveColumnDark.js";
import serverRedundancyIllustrationImageSrc from "images/server-redundancy-illustration.svg"
import serverSecureIllustrationImageSrc from "images/server-secure-illustration.svg"
import pirateQR from "images/pirate2.png"


export default () => {
  return (
    <AnimationRevealPage>
      <Hero />
      <Features />
      <Pricing />
      <MainFeature 
        subheading="Reliable"
        heading="Highly Available Servers"
        imageSrc={serverRedundancyIllustrationImageSrc}
        buttonRounded={false}
        buttonVisible={false}
        description="Q-ARGH leverages highly available and redundant servers to ensure continuous and reliable service, minimizing downtime and maximizing operational efficiency"
      />
      <MainFeature 
        subheading="Easy to use"
        heading="Quickly Track Items on monday.com"
        imageSrc={pirateQR}
        buttonRounded={false}
        textOnLeft={false}
        buttonVisible={false}
        description="Any pirate can now keep track of their items in a sea of stuff."
      />
      <Testimonial />
      {/* <FAQ /> */}
      <Footer />
    </AnimationRevealPage>
  );
}
